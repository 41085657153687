#navbar{
    display:flex;
    justify-content: space-between;
    /* background-color: ; */
}
#logo{
    font-size: x-large;
    margin-left: 2%;
    cursor: pointer;
    /* margin-top: 0%; */
    /* border:1px solid  */
}
#log{
    padding:2%;
    font-size: larger;
    display: flex;
    justify-content: flex-end;
    text-align: right;
    /* border:1px solid blue; */
    width:70%;
}
a{
    margin-left: 2%;
    font-size: larger;
    color:gray;
    text-decoration: none;
    font-family:sans-serif;
}
.signup{
    /* border:1px solid red; */
    
    margin-top: -1%;
    padding:7px;
    border-radius: 9px;
    background-color: #5c4ce3;
    color: aliceblue;
}
#logo,a:hover{
      color: #e15d29;
}
.signup:hover{
    color: white;
    background-color: #e15d29;
}