#container {
    display: flex;
    width:40%;
    margin: auto;
    text-align: center;
    margin-top: 60px;;
  }

  form {
    width: 10%;
  }

  input {
    margin-top: 5%;
  }
 
  #register-form{
      width:100%;
     
     }
     p{
         color:rgb(92, 90, 90);
         width:100px;
         text-align: left;
     }
     input{
         width:610px;
         padding: 13px;
         background-color: rgb(208, 217, 218);
         border:0ch;
     }
     button{
         font-size: large;
         margin-top:20px;
         width:200px;
         padding:9px;
         color: white;
         border:0px;
         background-color: #5c4ce3;
        margin-right: 450px;

     }
     button~p:last-of-type{
       width:100%;
       cursor: pointer;
     }
     a{
       color:#302e2e;
       text-decoration: none;
     }

     h1{
        color: #e15d29;
     }
     .link{
        color: #e15d29;
     }
     button:hover{
        background-color: #e15d29;
     }
     .link:hover{
        color:#5c4ce3
     }